<template>
    <div>
        <div class="researched-tracker-con"
            :class="{'clicked' : showResearchedTracker, 'with-weekly-quota': showWeeklyQuotaTracker }"
        >   
            <ArWeeklyQuotaTracker v-if="showWeeklyQuotaTrackerNotListingAndSearch" is-inline is-inside-researcher-tracker/>
            <i style="font-size:16px" @click="researchedTracker()" class="fa researcher-tracker-close">
                &#xf00d;
            </i>
            <template v-if="!$featureAccess.isRestricted('v-enable-auto-assignment')">
                <div class="researched-tracker-header" v-if="!showWeeklyQuotaTrackerNotListingAndSearch">
                    <div style="display: flex; align-items: flex-start; column-gap: 5px;">
                        <img class="researcher-tracker-icon" src="/static/svg/researched-tracker/tracker-button.svg">
                        <span class="researcher-tracker-label"> Auto Campaign Assignment Tracker </span>
                    </div>
                </div>
                <div v-if="currentAssignedCampaign?.is_fallback == 0 && waitingTracker && researchTrackerLoad" style="display: flex; flex-direction: column; row-gap: 30px;">
                    <div class="researcher-tracker-logo">
                        <img src="/static/svg/researched-tracker/campaign-bg.svg"
                            style="position: relative; height: 186px; width: 319px">
                        <div class="researcher-tracker-campaign-con">
                            <img :src="parent.getImage('campaigns', currentAssignedCampaign?.campaign || 'Default')" class="researcher-tracker-campaign">
                        </div>
                    </div>
                    <span v-show="currentAssignedCampaign?.is_for_further_research"  class="further-research-label">
                        For Further Research
                    </span>
                    <div class="tracker-campaign">
                        <span class="tracker-campaign-name" :title="currentAssignedCampaign.campaign">
                            {{ currentAssignedCampaign.campaign }}
                        </span>
                        <div class="d-flex align-items-center">
                            <span v-if="isUrgent" class="tracker-campaign-platform mr-2 is-urgent">
                                <img src="/static/svg/lead-tracker/exhausted_combo.svg" class="mr-1">
                                <span class="tracker-platform-name"> URGENT </span>
                            </span>
                            <span class="tracker-campaign-platform">
                                <img :src="parent.getImage('platforms', currentAssignedCampaign?.platform || 'Default')" style="height: 12px; width: 12px">
                                <span class="tracker-platform-name"> {{ currentAssignedCampaign.platform }} </span>
                            </span>
                        </div>
                    </div>
                    <div class="tracker-text">
                        <span class="tracker-text-desc"> You have been assigned to this campaign and platform. </span>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: center; row-gap: 10px">
                        <div v-show="!isUrgent" style="display: flex; column-gap: 5px; align-items: flex-end;">
                            <div class="tracker-progress">
                                <ArProgressBarWithSeparator :progress="currentProgress" />
                                <img src="/static/svg/researched-tracker/flag-icon.svg" class="flag-icon">
                            </div>
                        </div>
                        <div class="tracker-progress-length">
                            <span v-if="isUrgent" class="progress-length-desc"> <b>There's no limits on tagging listings as Researched.</b></span>
                            <span v-else-if="currentAssignedCampaign.is_fallback == 0" class="progress-length-desc">
                                {{ currentAssignedCampaign?.listing_count }} out of {{ currentAssignedCampaign?.target }} listings Researched
                            </span>
                            <span v-else class="progress-length-desc"> <b>You've already reached your quota! Hurray!</b></span>
                        </div>
                    </div>
                    <div class="progress-button-div">
                        <button class="tracker-progress-button" @click="showExhaustModal()">
                            Exhausted
                        </button>
                    </div>
                </div>
                <div v-else-if="currentAssignedCampaign?.is_fallback == 1 && waitingTracker && researchTrackerLoad" style="display: flex; flex-direction: column; row-gap: 30px;">
                        <div style="margin-top: 50px; display: flex; justify-content: center">
                            <img src="/static/svg/researched-tracker/fallback.svg" style="height:100%; width: 90%">
                        </div>
                    <div class="tracker-campaign" style="margin-top: -20px;" :class="currentAssignedCampaign?.is_fallback == 1 ? 'isFallback' : ''">
                        <span class="tracker-campaign-platform">
                            <img :src="parent.getImage('campaigns', currentAssignedCampaign?.campaign || 'Default')" style="height: 12px; width: 12px">
                            <span class="tracker-platform-name"> {{ currentAssignedCampaign.campaign }} </span>
                        </span>
                        <span class="tracker-campaign-platform">
                            <img :src="parent.getImage('platforms', currentAssignedCampaign?.platform || 'Default')" style="height: 12px; width: 12px">
                            <span class="tracker-platform-name"> {{ currentAssignedCampaign.platform }} </span>
                        </span>
                    </div>
                    <div class="tracker-text">
                        <span class="tracker-text-desc" style="font: normal normal bold 14px/21px Roboto;"> You are now on fallback </span>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <div class="tracker-progress-length">
                            <span v-if="currentAssignedCampaign.is_fallback == 0" class="progress-length-desc">
                                {{ currentAssignedCampaign?.listing_count }} out of {{ currentAssignedCampaign?.target }} listings Researched
                            </span>
                            <span v-else class="progress-length-desc"> <b>There's no limits on tagging listings as Researched.</b></span>
                        </div>
                    </div>
                    <div class="progress-button-div">
                        <button class="tracker-progress-button" @click="showExhaustModal()">
                            Exhausted
                        </button>
                    </div>
                </div>
                <div v-else-if="!waitingTracker" style="display: flex; flex-direction: column; row-gap: 30px;">
                    <div style="height: 280px; display: flex; justify-content: center; width: 100%">
                        <img src="/static/svg/researched-tracker/preparing-tracker.svg" class="tracker-pending">
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <span style="font: normal normal bold 16px/21px Roboto; 
                            letter-spacing: 0.25px; color: #515365;"
                        >
                            Preparing your tracker
                        </span>
                        <pre style="text-align: center; font: normal normal bold 12px/14px Roboto;
                                letter-spacing: 0.16px; color: #515365; margin-left: -65px; line-height: 1.5"
                        >
                            Please wait a bit while we set things up for you.
                        </pre>
                    </div>
                </div>
                <div v-else-if="researchTrackerLoad && !currentAssignedCampaign" class="tracker-pending-con">
                    <div style="text-align:center; display: flex; flex-direction: column; row-gap: 20px; margin-top: 20px">
                        <object type="image/svg+xml" data="/static/svg/researched-tracker/good-job.svg" />
                        <span style="font: normal normal bold 20px/21px Roboto; letter-spacing: 0.25px; color: #515365;">
                            Good Job!
                        </span>
                        <span style="font: normal normal bold 14px/14px Roboto; letter-spacing: 0.16px; color: #515365; width: 285px;">
                            You can now relax. You have no more urgent tasks to do.
                        </span>
                    </div>
                </div>
                <div v-else-if="!researchTrackerLoad" class="tracker-pending-con">
                    <div style="height: 280px; display: flex; justify-content: center; width: 100%">
                        <img src="/static/svg/researched-tracker/artemis_generating.svg" class="tracker-pending">
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: center;">
                        <span style="font: normal normal bold 16px/21px Roboto; 
                            letter-spacing: 0.25px; color: #515365;"
                        >
                            Hold Tight!
                        </span>
                        <pre style="text-align: center; font: normal normal bold 12px/14px Roboto;
                                letter-spacing: 0.16px; color: #515365; margin-left: -65px; line-height: 1.5"
                        >
                            We're working on generating another exciting
                            campaign and platform combination for you.
                        </pre>
                    </div>
                </div>
            </template>
            <div class="researcher-tracker-footer" v-if="(isWeeklyQuotaTrackerRestricted && !$featureAccess.isRestricted('v-enable-auto-assignment')) || ($featureAccess.isRestricted('v-enable-auto-assignment') && !isWeeklyQuotaTrackerRestricted)">
                <div style="margin-top: 130px;">
                    <img src="/static/svg/Logo.svg" style="height: 20px; width: 20px">
                    <span style="font: normal normal bold 11px/21px Roboto; 
                            letter-spacing: 0.14px; color: #515365;"
                    >
                        Artemis {{ new Date().getFullYear() }} | Verification 
                    </span>
                </div>
                <div>
                    <pre style="text-align: center; font: normal normal normal 9px/14px Roboto;
                        letter-spacing: 0px; color: #515365; margin-left: -35px"
                    >
                        This will provide you with the necessary information
                        that will serve as your central hub for managing and
                        monitoring your target listings.
                    </pre>
                </div>
            </div>
        </div>
        <ArDialog
            id="show-exhausted-tracker" 
            mbClass="d-flex justify-content-between p-0"
            :has_footer="false" 
            :has_button="false" 
            custom_width="718px" 
            custom_maxwidth="100%" 
            dialog_style="padding: 0" 
            large
        >
        <section class="text-left exhaust-left-con">
            <div class="exhaust-left">
                <span class="exhaust-left-head">Auto Campaign Assignment</span>
                <h5 class="exhaust-left-mark">Mark as Exhausted</h5>
            </div>
            <div class="exhaust-left-details-con">
                <div class="exhaust-left-campaign">
                    <div class="exhaust-left-campaign-con">
                        <img :src="parent.getImage('campaigns', currentAssignedCampaign?.campaign || 'Default')" class="researcher-tracker-campaign">
                    </div>
                    <div class="exhaust-left-campaign-danger">
                        <img src="/static/svg/researched-tracker/exhausted-icon.svg" class="exhaust-campaign-danger">
                    </div>
                </div>
                <div class="exhaust-left-campaign-details">
                    <span style="font: normal normal bold 14px/21px Roboto; letter-spacing: 0px;"> Emoji </span>
                    <div class="exhaust-platform-con">
                        <span class="exhaust-platform">
                            <img :src="parent.getImage('platforms', currentAssignedCampaign?.platform || 'Default')" class="platform-icon">
                            <span class="platform-name">{{ currentAssignedCampaign?.platform }}</span>
                        </span>
                        <span class="platform-date">
                            {{ formattedDate }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="exhaust-left-note">
                <p class="exhaust-left-p1">
                    Please note that this will trigger
                    an independent review to verify that
                    the campaign and platform is
                    actually exhausted.
                </p>
                <p class="exhaust-left-p2">
                    Abuse of the "Exhausted" button may
                    lead to potential disciplinary action,
                    including but not limited to reduction and/or
                    removal of commissions.
                </p>
            </div>
            <div class="exhaust-left-button">
                <button type="button" class="confirm-exhaust" @click="markAsExhausted()">
                    I got it, I like to proceed
                </button>
                <button type="button" class="cancel-exhaust" @click="closeExhaustModal()">
                    Please cancel my request
                </button>
            </div>
        </section>
        <section class="exhaust-right-con">
            <img src="/static/svg/researched-tracker/bg-exhausted.png" class="bg" alt="bg-splash-img">
            <img src="/static/svg/researched-tracker/exhausted.svg" class="splash-img">
        </section>
    </ArDialog>
    <portal>
        <CongratulatoryModal />
    </portal>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions, mapGetters } from 'vuex'
import { send } from '../../../events/exhausted-assignment-logs'
    export default {
        name: "ResearchedTrackerModal",
        components: {
        ...vm.$layouts.getLayouts([
            "ArDialog",
            "ArTextfield",
            "ArButton",
            "ArComboCard",
            "ArProgressBarWithSeparator",
            "ArWeeklyQuotaTracker"
            ]),
            CongratulatoryModal: () => import("@/components/layouts/modals/CongratulatoryModal"),
        },
        data() {
            return {
                formattedDate: '',
                headers: {},
            }
        },
        watch: {
            currentProgress(newVal) {
                if (newVal == 100 && !this.isUrgent) {
                    this.showCongratsMsg();
                }
            },
            '$route.name'() {
                this.$store.dispatch('setResearchedTracker', false)
            }
        },
        mounted() {
            this.updateDate();
            this.getUserAssignments();
            this.setResearchTrackerLoad();
        },
        computed: {
            ...mapState(['showResearchedTracker', 'currentAssignedCampaign', 'researchTrackerLoad', 'waitingTracker']),
            parent() {
                let p = this.$parent
                let count = 0

                while (p.$vnode.componentOptions.tag != 'ar-master' && count < 6) {
                    p = p['$parent']
                    count++
                }

                return p
            },
            env() {
                return process.env.VUE_APP_URL
            },
            currentProgress() {
                let progress = this.currentAssignedCampaign?.listing_count
                let target = this.currentAssignedCampaign?.target
                return progress / target * 100
            },
            isUrgent() {
                return this.currentAssignedCampaign?.priority == "URGENT"
            },
            isWeeklyQuotaTrackerRestricted() {
                return this.$featureAccess.isRestricted('v-enable-weekly-quota-tracker')
            },
            showWeeklyQuotaTracker() {
                return !this.isWeeklyQuotaTrackerRestricted && ['listing', 'search'].includes(this.$route.name)
            },
            showWeeklyQuotaTrackerNotListingAndSearch() {
                return !this.isWeeklyQuotaTrackerRestricted && !['listing', 'search'].includes(this.$route.name)
            }
        },
        methods: {
            researchedTracker() {
                this.$store.dispatch('setResearchedTracker', !this.showResearchedTracker)
            },
            showExhaustModal() {
                $('#show-exhausted-tracker').modal('show')
            },
            showCongratsMsg() {
                $('#show-congrats-message').modal('show')
                this.closeExhaustModal()
                setTimeout(() => {
                    this.closeCongratsMsg();
                },5000);
            },
            closeExhaustModal() {
                $('#show-exhausted-tracker').modal('hide')
            },
            closeCongratsMsg() {
                $('#show-congrats-message').modal('hide')
            },
            updateDate() {
                const months = [
                    'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
                    'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
                ];

                const today = new Date();
                const day = String(today.getDate()).padStart(2, '0');
                const month = months[today.getMonth()];
                const year = today.getFullYear();

                this.formattedDate = `${day} ${month} ${year}`;
            },
            getUserAssignments() {
                setTimeout(() => {
                    this.$store.dispatch('userAssignments')
                },)
            },
            setResearchTrackerLoad() {
                setTimeout(() => {
                    this.$store.dispatch('setResearcherTrackerLoad', true)
                }, 5000)

            },
            markAsExhausted() {
                this.parent.showSnackbar('Processing... please wait.', true)
                let id = this.currentAssignedCampaign?.id
                let campaign = this.currentAssignedCampaign?.campaign
                let platform = this.currentAssignedCampaign?.platform
                let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                this.$http.patch(`api/user-assignments/${id}`, {
                    exhausted_flag: 1
                }, headers)
                .then(response => {
                    this.closeExhaustModal()
                    this.parent.showSnackbar('Campaign is now exhausted.', false, 'success', 'fas fa-check')

                    // Notify lead assigned with the exhausted campaign
                    const campaignId = this.currentAssignedCampaign?.campaignId;
                    send({ campaignId, campaign, platform });
                    this.$store.dispatch('setResearcherTrackerLoad', false)
                    this.setResearchTrackerLoad()
                    this.getUserAssignments()
                }).catch(err => {
                    this.parent.showSnackbar("Something went wrong. Please contact system administrator.", false, "invalid", "fas fa-times-circle")
                })
            },
        }
    }
</script>

<style scoped>
    .researched-tracker-con {
        width: 300px;
        /* height: 600px; */
        height: 0px;
        opacity: 0;
        position: fixed;
        top: 60px;
        right: 10px;
        z-index: 99;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000029;
        border: 1px solid #51536547;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 5px;
        -webkit-transition: opacity 0.3s ease, height 0.3s ease;
        transition: opacity 0.3s ease, height 0.3s ease;
        pointer-events: none;
    }
    .researched-tracker-con.clicked {
        width: 300px;
        height: calc(100vh - 66px);
        opacity: 1;
        pointer-events: all;
    }

    /** Weekly quota tracker parts **/
    .researched-tracker-con.with-weekly-quota {
        top: 172px;
    }
    .researched-tracker-con.with-weekly-quota.clicked {
        height: calc(100vh - 179px);
    }

    .researched-tracker-header {
        display: flex;
        justify-content: space-between;
        column-gap: 5px;
        height: 20px;
        align-items: center;
        padding-right: 5px;
        padding-bottom: 10px;
        margin-top: 10px;
        border-bottom: 1px solid #51536547;
    }
    .researcher-tracker-label {
        font: normal normal bold 13px/21px Roboto;
        letter-spacing: 0.22px;
        color: #515365;
    }
    .further-research-label {
        text-align: center;
        margin-top: 14px;
        text-transform: uppercase;
        font: normal normal bold 11px/21px Roboto;
        color: rgb(255, 94, 94);
        margin-bottom: -35px;
    }
    .researcher-tracker-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 5;
    }
    .researcher-tracker-logo {
        height: 134px;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .researcher-tracker-campaign-con {
        position: absolute;
        overflow: hidden;
        height: 75px;
        width: 75px;
        border-radius: 100%;
        /* top: 110px;
        left: 112px; */
        top: 56px;
        left: 107px;
    }
    .researcher-tracker-campaign {
        height: 100%;
        width: 100%;
        transform: scale(1.2)
    }
    .tracker-campaign {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
        margin-top: 25px;
    }
    .tracker-campaign.isFallback {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        margin-top: 25px;
    }
    .tracker-campaign-name {
        font: normal normal bold 18px/21px Roboto;
        letter-spacing: 0.36px;
        color: #515365;
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
    }
    .tracker-platform-name {
        font: normal normal bold 10px/21px Roboto;
        letter-spacing: -0.2px;
        color: #383A3D;
    }
    .tracker-campaign-platform {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #383A3D26;
        border-radius: 4px;
        padding: 2px 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
        width:fit-content;
    }

    .tracker-campaign-platform.is-urgent {
        background: #DC3C401C; 
        color: #DC3C40; 
        border: 1px solid #DC3C40; 
        font-size: 15px;
    }

    .tracker-campaign-platform.is-urgent img {
        height: 13px;
        width: 13px
    }

    .tracker-campaign-platform.is-urgent span {
        color: #DC3C40 !important;
    }

    .tracker-text {
        text-align: center;
        font: normal normal bold 12px/18px Roboto;
        letter-spacing: 0px;
        color: #515365;
        display: flex;
        justify-content: center;
        width: 287px;
    }
    .tracker-text-desc {
        width: 217px;
    }
    .tracker-progress {
        position: relative;
        height: 15px;
        display: flex;
        align-items: flex-end;
        column-gap: 2px;
    }
    .flag-icon {
        position: absolute;
        height: 20px; width: 15px;
        top: -16px;
        right: -17px;
    }
    .progress-length-desc {
        font: normal normal normal 10px/18px Roboto;
        letter-spacing: 0px;
        color: #515365;
    }
    .progress-button-div {
        width: auto;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        position: relative;
        z-index: 2;
    }
    .tracker-progress-button {
        border: 1px solid #DC3C40;
        width: 200px;
        height: 32px;
        border-radius: 6px;
        transition: .3s ease;
        color: #FFFFFF;
        font-weight: bold;
        position: relative;
        background: transparent;
        overflow: hidden;
        outline: none;
    }

    .tracker-progress-button:after {
        content:"";
        height: 64px;
        width: 100%;
        position: absolute;
        background: transparent linear-gradient(180deg, #FFDCDD 0%, #F5686C 70%, #7B3436 100%) 0% 0% no-repeat padding-box;
        top: -45%;
        left: 0;
        right: 0;
        z-index: -1;
        -webkit-transition: .3s ease;
        transition: .3s ease;
    }
    .tracker-progress-button:hover {
        border: 1px solid #DC3C40;
        color: #DC3C40;
    }

    .tracker-progress-button:hover:after {
        top: 0;
    }
    .researcher-tracker-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 250px;
        width: 297px;
        position: absolute;
        bottom: 4px;
        left: 0px;
        background-image: url("/static/svg/researched-tracker/tracker-bg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    .tracker-pending-con {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-top: 20px;
    }
    .tracker-pending {
        height: 350px;
        width: 100%;
    }
    .exhaust-left-details-con {
        display: flex;
        border-bottom: 1px solid #51536547;
        height: 80px;
        width: 310px;
    }
    .exhaust-left {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .exhaust-left-con {
        padding: 27px 30px 0 30px;
    }
    .exhaust-left-head {
        text-align: left;
        font: normal normal bold 12px/13px Roboto;
        letter-spacing: 0px;
        color: #3E88DA;
    }
    .exhaust-left-mark {
        font: normal normal bold 24px/28px Roboto;
        letter-spacing: 0px;
        color: #383A3D;
    }
    .exhaust-left-campaign-con {
        position: absolute;
        overflow: hidden;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        top: 85px;
        left: 30px;
        padding: 2px;
        border: 1px solid #383A3D;
    }
    .exhaust-campaign-danger {
        position: absolute;
        top: 116px;
        left: 63px;
    }
    .exhaust-left-campaign-details {
        margin-left: 70px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .exhaust-platform-con {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
    }
    .exhaust-platform {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 3px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #383A3D26;
        border-radius: 4px;
        width: fit-content;
        padding: 3px;
        height: 20px;
    }
    .platform-icon {
        width: 12px;
        height: 12px;
    }
    .platform-name {
        font: normal normal bold 10px/21px Roboto;
        letter-spacing: -0.2px;
        color: #383A3D;
        text-transform: uppercase;
    }
    .platform-date {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #383A3D26;
        border-radius: 4px;
        width: 65px;
        height: 20px;
        font: normal normal bold 10px/21px Roboto;
        letter-spacing: -0.2px;
        color: #515365;
    }
    .exhaust-left-note {
        display: flex;
        flex-direction: column;
        width: 310px;
        margin-top: 25px;
        row-gap: 30px;
    }
    .exhaust-left-p1 {
        font: normal normal bold 16px/22px Roboto;
        letter-spacing: 0px;
    }
    .exhaust-left-p2 {
        font: normal normal normal 16px/22px Roboto;
        letter-spacing: 0px;
    }
    .exhaust-left-button {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .confirm-exhaust {
        width: 286px;
        height: 36px;
        background: #DC3C40 0% 0% no-repeat padding-box;
        border: none;
        outline: none;
        border-radius: 18px;
        font: normal normal bold 14px/19px Roboto;
        letter-spacing: 1.25px;
        color: #FFFFFF;
        transition: 0.3s;
    }
    .confirm-exhaust:hover {
        background: #F5686C 0% 0% no-repeat padding-box;
        transition: 0.3s;
    }
    .cancel-exhaust {
        text-decoration: underline;
        font: normal normal bold 14px/19px Roboto;
        letter-spacing: 1.25px;
        color: #383A3D;
        border: none;
        outline: none;
        background: #FFFFFF;
        transition: 0.3s;
    }
    .cancel-exhaust:hover {
        opacity: 0.55;
        transition: 0.3s;
    }
    .campaign-platform-con {
        margin-top: -20px;
    }
    .exhaust-right-con {
        width: 400px;
        min-height: 565px;
        position: relative;
    }
    .exhaust-right-con .bg {
        position: absolute;
        right: -8px;
        top: -5px;
        height: 100%;
        width: 102%;
    }
    .exhaust-right-con .splash-img {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 336px;
    }

    .content {
        width: 300px;
        height: 200px;
        background-color: #f0f0f0;
        padding: 20px;
    }
</style>
